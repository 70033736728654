// Utilities
import { defineStore } from 'pinia'
import { useCookies } from 'vue3-cookies'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { allcategory } from '@/sampleApi/category'
import api from '@/api/api'
import { toRaw } from 'vue'
import { deleteUrlParams, addUrlParams } from '@/utils'
import { stat } from 'fs'
import CryptoJS from 'crypto-js'
import { getUtcTimeStamp } from '@/utils'
import { SHA512 } from 'crypto-js'
import { fa, tr } from 'element-plus/es/locale'
import { ElMessage } from 'element-plus'

const { cookies } = useCookies()
const $http = axios.create({})

export const useAppStore = defineStore('vuexyStore', {
  state: () => ({
    version: 'v1.18.1',
    devVersion: process.env.VITE_VERSION, // 開發版本[p1,p2]
    isSimpleMode: false, //Demo P2 加入行程-簡易模式用
    isLogin: false, // 是否已登入
    memberId: '',
    isHotaiBind: false, // 是否已綁定和泰
    memberAvatarUrl: '', // 會員頭像
    userOS: '',
    hotaiMallUrl: '', // EC商城網址
    hotaiInsurancelUrl: '', // 和泰產險網址(旅平險)
    agodaUrl: '', // agoda 網址(找住宿)
    // 使用者當前坐標位置
    userPosition: {
      latitude: 0,
      longitude: 0,
    },
    // 使用者當前所在區域
    userCurrentLocation: {},
    // 預設區域，預設為台北市
    defaultLocation: {
      id: 89,
      latitude: 25.0441137,
      longitude: 121.5630712,
      name: '台北市',
      parentId: 16,
      zoomLevel: 11,
    },
    handleHeader: true as boolean, // 是否顯示 header 及控制項
    isCollapse: true, // 主選單文字是否顯示
    debounceTimer: null as any, // 防止 safari 側選單抖動
    selectedArea: {
      name: '選擇區域',
      id: -1,
      zoomLevel: 9.5,
      latitude: 0,
      longitude: 0,
    },
    recentSelectedAreaList: [], // 最近瀏覽區域
    // 我的分類選單
    categoryList: [],
    selectedCategoryIndex: 0, // 已選取的分類
    keywordTypeList: [
      { name: '關鍵字', id: 'keyword' },
      { name: '地址', id: 'address' },
    ],
    mapReady: false, // map 是否載入完成
    showMap: false, // 是否為地圖模式(首次仔入預設需顯示才可正常抓到短邊)
    // 當前地圖中心點座標
    mapCenterPositon: {
      latitude: 0,
      longitude: 0,
    },
    // 地圖短邊座標
    mapBoundaryPosition: {
      latitude: 0,
      longitude: 0,
    },
    firstLoadingDone: false, // 首屏載入是否完成
    allowShowLoading: true, // 允許顯示載入畫面（需求變動，首頁第一次載入顯示Loading）
    mapChanging: false, // 地圖是否正在位移或縮放中
    // 地圖目前hover的Poi座標，如無則傳null
    mapHoverPoi: null,
    // 地圖是否有選定的poi，如有則將mapHoverPoi置於地圖中心
    mapIsPinPoi: false,
    zoomInPinPoi: false, // 有選定的poi時是否zoomIn地圖
    movePinPoi: false, // 有選定的poi時是否移動地圖設將poi設為中心點
    keywordMode: false, // 關鍵字模式
    selectedKeywordType: 'keyword', // 已選取的關鍵字類型
    searckKeyword: '',
    displayMode: 'poi', // 排序方式 poi(熱門景點)/media(熱門貼文)
    showTravel: true, // 是否顯示行程列表
    showCategoryDialog: false, // 是否顯示我的分類彈窗
    showAddPoiTsd: false, // 是否顯示POI加入行程彈窗
    showAddPoisTsd: false, // 是否顯示多個POI加入行程彈窗
    poiAddTsdContent: null as any, // POI加入行程POI內容
    showAddMedia: false, // 是否顯示 POI 上傳影音彈窗
    showMediaSettingDialog: false, // 是否顯示影音設定彈窗
    showPoiListDialog: true, // 地圖模式是否顯示熱門景點列表
    showAreaSettingDialog: false, // 是否顯示選擇區域彈窗
    showLoginDialog: false, // 是否顯示登入彈窗
    showPoiDialog: false, // 是否顯示POI詳細資訊彈窗
    showTrafficSetting: false, // 右側交通方案列表
    isMute: true, // poi 詳情播放影片是否靜音
    loginSalt: '9eGHc6@b49AU1d8#Mfvw', // 產生token用
    homePageTitle: '去趣chicTrip',
    // TSD 設定交通方式
    trafficSettingData: {
      permission: '', //編輯權限(Owner)
      day: 0, //當前天數
      tsdIndex: 0, // 要編輯的TSD的流水號
      type: '', // [copy:複製TSD | edit:點擊列表交通]
    },
    currentTrafficDetail: {} as object, // 當前選擇交通路線 for 交通路線地圖
    poiDialogSetting: {
      // POI詳細資訊設置
      poiId: '',
      mediaId: '',
    },
    uploadMediaData: {
      // 貢獻影音資料
      placeType: 'none', // none: 無景點（從側選單貢獻影音進來）, custom: 自訂景點（從地圖自訂景點進來）, poi: 有景點（從poi詳細資訊進來）
      uploadMediaPoiId: '', // 貢獻影音的poiId
      customPoiPlaceData: null, // 自訂地點資料
      customMarker: null, // 自訂地點座標
    },
    tsdDialogSetting: {
      // TSD詳細資訊設置
      tsdId: '',
      travelScheduleId: '',
      TravelScheduleUpdateTime: 0,
      openMedia: false,
      permission: '',
      poiId: '',
    },
    poiDialogIsTSD: false, //POI詳細資訊彈窗 - 是否為TSD
    poiList: [], // poi景點清單（搜尋結果）
    apiPoiData: null as any, //poi資料
    // 分類的icon對照表
    categoryIconList: {
      enterTainment: {
        iconClassName: 'entertainment',
      },
      food: {
        iconClassName: 'food',
      },
      shop: {
        iconClassName: 'shop',
      },
      moon: {
        iconClassName: 'bed',
      },
      train: {
        iconClassName: 'train',
      },
      rentCar: {
        iconClassName: 'carkey',
      },
      plane: {
        iconClassName: 'plane',
      },
      chargingPoint: {
        iconClassName: 'electric_station',
      },
      other: {
        iconClassName: 'others',
      },
      takeOff: {
        iconClassName: 'plane_takeoff',
      },
      transfer: {
        iconClassName: 'plane_transfer',
      },
      landing: {
        iconClassName: 'plane_landing',
      },
      heart: {
        iconClassName: 'heart',
      },
      parking: {
        iconClassName: 'park_car',
      },
    },
    myTravelList: [
      {
        id: '13bdaea3-0884-47bb-9cbb-9fcf2d4ce96b',
        memberId: '6ac2b377-e99b-4f9d-93aa-ba076b551407',
        coverMediaId: '6277ca04-6f2a-4f30-90b6-50fa35c0edf9',
        coverUrl:
          'https://devchicrtipstorage.blob.core.windows.net/system/6277ca04-6f2a-4f30-90b6-50fa35c0edf9.jpg',
        name: '回憶之旅',
        startDate: '2023/06/01',
        endDate: '2023/06/02',
        totalDay: 2,
        userLabelId: '29bab8ae-5398-4e58-b10f-35853404b7a4',
        userLabel: '未標籤',
        createTime: 1677206235,
        updateTime: 1677224641,
        collaborationCount: 0,
        permission: 'Owner',
        viewMode: 'DetailMode',
      },
      {
        id: '13bdaea3-0884-47bb-9cbb-9fcf2d4ce96c',
        memberId: '6ac2b377-e99b-4f9d-93aa-ba076b551407',
        coverMediaId: '6277ca04-6f2a-4f30-90b6-50fa35c0edf9',
        coverUrl:
          'https://devchicrtipstorage.blob.core.windows.net/system/6277ca04-6f2a-4f30-90b6-50fa35c0edf9.jpg',
        name: '日本',
        startDate: '2023/06/01',
        endDate: '2023/06/02',
        totalDay: 2,
        userLabelId: '29bab8ae-5398-4e58-b10f-35853404b7a4',
        userLabel: '未標籤',
        createTime: 1677206235,
        updateTime: 1677224541,
        collaborationCount: 0,
        permission: 'Owner',
        viewMode: 'DetailMode',
      },
      {
        id: '13bdaea3-0884-47bb-9cbb-9fcf2d4ce96d',
        memberId: '6ac2b377-e99b-4f9d-93aa-ba076b551407',
        coverMediaId: '6277ca04-6f2a-4f30-90b6-50fa35c0edf9',
        coverUrl:
          'https://devchicrtipstorage.blob.core.windows.net/system/6277ca04-6f2a-4f30-90b6-50fa35c0edf9.jpg',
        name: '韓國',
        startDate: '2023/06/01',
        endDate: '2023/06/02',
        totalDay: 2,
        userLabelId: '29bab8ae-5398-4e58-b10f-35853404b7a4',
        userLabel: '未標籤',
        createTime: 1677206235,
        updateTime: 1677224341,
        collaborationCount: 0,
        permission: 'Owner',
        viewMode: 'DetailMode',
      },
    ],
    // 最近編輯行程
    lastEditedSchedule: {
      id: '13bdaea3-0884-47bb-9cbb-9fcf2d4ce96b',
      name: '加入行程',
    } as any,

    // 我的行程排序「建立時間排序:createtime, 修改時間排序:updatetime, 標籤排序:userlabel.sort」
    MyScheduleSortStore: 'userlabel.sort',
    // 共編行程列表排序「建立時間排序:travelSchedule.createtime, 修改時間排序:travelSchedule.updatetime,」
    CollaborationSortStore: 'travelSchedule.createtime',

    travelScheduleSortColumn: 'updatetime', // 排序種類[updatetime、createtime]
    travelScheduleSortOrder: 'desc', // 排序狀態[asc、desc]

    // 取得行程列表[我的行程]
    travelSchedule: [] as any,
    // 取得行程列表[共編]
    travelScheduleCollaboration: [] as any,
    // 顯示載入中次數
    loadingCount: 0,
    // 是否為中小網
    isMobile: true,
    // 是否開啟加入共編彈窗
    showJoinCollaborative: false,

    // 是否開啟行程設定彈窗
    showTravelScheduleSettings: false,

    // 是否為行程設定（true:行程設定 false:新增行程）
    isTravelScheduleSetting: false,

    // 是否開啟hashtag彈窗
    showHashtagSettings: false,

    // 是否開啟選擇hashtag彈窗
    showSelectHashtag: {
      show: false,
      triggerEditAPI: true, //觸發編輯api
    },
    //編輯標籤所選擇的標籤id
    selectHashtagId: '',

    // 行程標籤列表
    travelScheduleUserLabel: [] as any,

    // 編輯行程標籤
    defaulTravelScheduleUserLabel: {} as any,

    // 個人[未標籤] ID
    defaulUserLabel: '',

    // 是否顯示 Yoxi 叫車彈窗
    showYoxidialog: false,

    // 是否顯示 Irent 租車彈窗
    showIrentdialog: false,

    // 是否顯示 匯率彈窗
    showExchangeRatedialog: false,

    // 是否顯示 日本周遊券彈窗
    showJapanPassDialog: false,

    // 是否顯示 行程找航班彈窗
    showFlightSearchDialog: false,

    // 是否顯示 航班搜尋結果彈窗
    showFlightResultDialog: false,

    // 是否顯示 依照航班調整行程時間彈窗
    showFlightScheduleSettingDialog: false,

    // 航班搜尋結果資料
    flightResultData: {},

    // 是否顯示 app下載bar
    showAppDownload: true,

    // app下載bar 階層在最上面
    appDownloadTop: false,

    // 04. 行程列表 - 取得行程列表 TravelSchedule/Get
    travelScheduleList: [] as any,

    // 04. 行程列表 - 取得行程列表包含細節 TravelSchedule/GetWithDetail
    travelScheduleWithDetail: [
      {
        travelScheduleInfo: {} as any,
        dayList: [] as any,
      },
    ],

    //04. 行程列表 - 取得系統行程封面圖列表
    systemCoverList: [] as any,

    //04. 行程列表 - 取得行程列表筆記
    tavelScheduleNote: '',
    isOpenTavelScheduleNote: false, // 是否開啟編輯行程列表筆記

    // 05. 產生 產生 邀請共編頁 DeepLink
    collaborationDeepLin: '',
    // 05. 產生 產生 邀請共編頁 連結文案
    collaborationText: '',

    // 05. 產生 產生 預覽行程 DeepLink
    preViewDeepLink: '',

    // 06. 行程共編和分享 - 取得與我共編的行程 TravelSchedule/GetCollaboration
    collaborationList: [] as any,
    // 06. 行程共編和分享 - 取得與我共編的行程包含細節 TravelSchedule/GetCollaborationWithDetail
    collaborationWithDetail: [
      {
        travelScheduleInfo: {} as any,
        dayList: [] as any,
      },
    ],

    // 06. 行程共編和分享 - 取得行程分享文字檔
    travelScheduleShareText: '',

    // 06. 行程共編和分享 - 取得行程分享連結
    travelScheduleShareLink: '',

    // 06. 行程共編和分享 - 行程共同編輯資料(成員列表)
    travelScheduleCollaborationData: {
      travelSchedule: {
        name: '',
        coverUrl: '',
        startDate: '',
        endDate: '',
      },
      owner: {
        memberId: '',
        name: '',
        photoUrl: '',
        followerCount: 0,
        travelScheduleCount: 0,
        mediaCount: 0,
      },
      collaborationList: [],
    },

    // 06. 行程共編和分享 - 行程共同編輯資料(WithToke)
    travelScheduleCollaborationWithTokenData: {
      travelSchedule: {
        name: '',
        coverUrl: '',
        startDate: '',
        endDate: '',
      },
      owner: {
        memberId: '',
        name: '',
        photoUrl: '',
        followerCount: 0,
        travelScheduleCount: 0,
        mediaCount: 0,
      },
      collaborationList: [],
    },

    // 08. 行程細節 - 行程TSD列表 TravelScheduleDetail/Get
    travelScheduleDetail: {
      travelScheduleInfo: {} as any,
      dayList: [] as any,
    },

    // 行程分享/邀請共編
    shareSchedule: {
      id: '',
      type: 'share', //分享：share 共編：collaboration
      showTab: true, // 顯示
    },
    shareScheduleData: {} as any,

    showScheduleDetail: false, //是否顯示「行程細節」
    isScheduleDetailMin: false, //「行程細節」在手機版是否縮小

    //目前顯示行程細節「行程資料」
    showScheduleDetailData: {
      travelScheduleId: '',
      travelScheduleName: '',
      TravelScheduleUpdateTime: '',
      isMyTravelSchedule: 1,
    },

    isQuitCollaboration: false, //是否已離開行程共編
    quitCollaborationName: '', // 已離開行程共編名稱

    isScheduleDetailUpdated: false, // 是否TSD內容有更新
    editTSDNoteId: '', //目前編輯筆記的TSD ID
    tsdIdToSetting: '', //目前編輯TSD 的ID
    tsdTrafficId: '', //目前編輯TSD交通方式的 ID
    tripToolId: '', //行程工具設定的 ID
    tsdCurrentDay: 0, // 當前TSD顯示的day
    // 地圖目前hover的TSD座標，如無則傳null
    mapHoverTSD: null,
    // 地圖是否有選定的tsd，如有則將mapHoverTSD置於地圖中心
    mapIsPinTSD: false,
    zoomInPinTSD: false, // 有選定的TSD時是否zoomIn地圖
    movePinTSD: false, // 有選定的tsd時是否移動地圖設將tsd設為中心點

    mapHoverPlaylist: null,
    // 地圖是否有選定的tsd，如有則將mapHover收藏置於地圖中心
    mapIsPinPlaylist: false,
    zoomInPinPlaylist: false, // 有選定的收藏poi時是否zoomIn地圖
    movePinPlaylist: false, // 有選定的收藏poi時是否移動地圖設將設為中心點

    isSearchThisArea: false, //是否執行[搜尋此區域]
    arrivalTrafficTypeList: [
      { key: 'Walking', name: '走路' },
      { key: 'Driving', name: '汽車' },
      { key: 'Transit', name: '大眾運輸' },
      { key: 'TwoWheeler', name: '機車' },
      { key: 'Custom', name: '自訂' },
    ],
    travelScheduleAreas: [] as any, //旅遊行程目的地
    showTSBookPopup: false,
    collectioIndex: 0, //類別[收藏]的index

    isPreView: false, // 是否為預覽模式
    isPreViewPlaylist: false, // 是否為收藏預覽模式
    preViewPlaylistErr: false, // 收藏預覽模式錯誤
    TSDpermission: '', // TSD 操作權限 PreView 自己：Owner 可編輯：Editor 檢視：Viewer
    hasEditTSDPermission: false, //是否有編輯TSD權限
    preViewData: {} as any, // 預覽資料
    preViewLoading: false, // 預覽載入狀態

    updateTimeConflict: null, // 時間衝突

    isCollaborationPreView: false, //預覽時使否為邀請共編
    isPreViewCheckCollaboration: false, //從預覽加入共編
    isSearchAround: false, // 搜尋周邊景點
    zoomInPolyline: '', // 地圖zoomin至路線
    isShowEmailOrder: false, // email匯入訂單(航班通知)
    showFlightInfo: false, // 開啟航班資訊
    showFlightToSchedule: false, //航班加入行程(光箱開啟)
    playlistCategoryId: 'b8ad0709-4d5f-4379-9331-ed5693d22ea7',
    isErrorCode_012: false, // 012錯誤狀態
    isErrorCode_013: false, // 013錯誤狀態
    isErrorCode_014: false, // 014錯誤狀態
    errorMsg014: '', // 系統維護錯誤訊息

    showPlaylistSelectDialog: false, // 是否顯示選擇收藏清單彈窗
    showPlaylistFromTsd: false, // 收藏清單彈窗來源是否為TSD
    showPlaylistCreateDialog: false, // 是否顯示新增收藏清單彈窗
    showDuplicatePoiDialog: false, // 是否顯示複製景點彈窗
    showDuplicatePoisDialog: false, // 是否顯示複製多個景點彈窗
    isEditPlaylistDialog: false, // 彈窗顯示為編輯清單 / 新增清單
    duplicatePoiId: '', // 複製景點彈窗的 poi 資料
    tempUnfavoritePois: [] as string[], // 暫存被移除於收藏清單中的 pois，用來判斷收藏愛心狀態
    isPoiHeartUpdate: false, // 開啟 Poi 彈窗時是否更新收藏清單
    isDuplicateDialogFromPoi: false, // 複製景點彈窗是否開啟於 Poi 彈窗

    playlistsData: [], // 收藏清單列表
    playlistActiveID: null as any, // 目前瀏覽的收藏ID
    playlistActiveData: null as any, // 目前瀏覽的收藏Data
    playlistActivePois: [], // 目前瀏覽的收藏POI列表

    isSelectablePlayList: false, // 收藏選取狀態
    selectedPlayListItems: [] as any, // 收藏選取項目
    selectAllPlayListID: null as any, // 收藏poi全選的ID
    // 收藏清單顏色
    playListColors: [
      { color: 'favo01', fill: '#DC9389', stroke: '#CD6E55' },
      { color: 'favo02', fill: '#85A09A', stroke: '#617A75' },
      { color: 'favo03', fill: '#9694A4', stroke: '#737182' },
      { color: 'favo04', fill: '#658AAD', stroke: '#4F6471' },
      { color: 'favo05', fill: '#6D64A4', stroke: '#47426D' },
      { color: 'favo06', fill: '#764E56', stroke: '#552D36' },
      { color: 'favo07', fill: '#090C08', stroke: '#000' },
      { color: 'favo08', fill: '#F09', stroke: '#AE0464' },
      { color: 'favo09', fill: '#D45113', stroke: '#793E09' },
      { color: 'favo10', fill: '#E0CA00', stroke: '#AB9A00' },
      { color: 'favo11', fill: '#59D62D', stroke: '#32A300' },
      { color: 'favo12', fill: '#52E3E1', stroke: '#00B1B0' },
      { color: 'favo13', fill: '#D883FF', stroke: '#AB0FDB' },
      { color: 'favo14', fill: '#9336FD', stroke: '#6205C1' },
    ],

    playlistDeepLink: '',
    poiIsLikeList: [] as string[], // 紀錄poi牌卡愛心狀態列表

    // === 達人推薦 ===
    isExpertSearchActive: false, // 舊版關鍵字搜尋是否為 focus 狀態
    showExpertSearchAreaDialog: false, // 是否顯示找地區彈窗
    showTourFilterDialog: false, // 是否顯示篩選行程彈窗
    allSearchLocationData: [] as any, // 所有可查詢地區資料
    searchingKey: {
      locationKey: null as string[] | null | undefined, // 地點
      searchKey: null as string | null | undefined, // 關鍵字
      totalDayKey: null as string[] | null | undefined, // 行程天數
      monthKey: null as string[] | null | undefined, // 推薦月份
      travelPreferKey: null as string[] | null | undefined, // 主題
    }, // 搜尋選項（同網址 query）

    showExpertSchedule: true, // 達人細節列表 (顯示)
    isExpertSchedulelMin: false, // 達人細節列表 (縮小收合)
    travelScheduleInfo: null, // 達人行程
    expertDayList: [], // 達人行程天數列表
    expertCurrentDay: 0, // 當前達人顯示的day
    prevPageURL: null as string | null | undefined, //前一頁網址
  }),
  getters: {
    isShowLoading(state) {
      // console.log('測試先關閉Loading::', state.loadingCount)
      // return false
      return this.allowShowLoading && state.loadingCount > 0
    },
    /** 首頁分類是否選擇「收藏」 ，寫死收藏ID為'b8ad0709-4d5f-4379-9331-ed5693d22ea7' */
    isSelectedPlaylist(state) {
      // 預覽收藏時直接設定成查看收藏模式
      if (this.isPreViewPlaylist) {
        return true
      }

      return (
        this.categoryList[state?.selectedCategoryIndex]?.id ==
        'b8ad0709-4d5f-4379-9331-ed5693d22ea7'
      )
    },
  },
  actions: {
    // 取得用戶裝置作業系統
    getUserOS() {
      if (
        window.navigator.userAgent.match(/Macintosh|MacIntel|MacPPC|Mac68K/)
      ) {
        this.userOS = 'AppleMac'
      } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/)) {
        this.userOS = 'AppleIos'
      } else {
        this.userOS = 'Other'
      }
    },
    /** 取得使用者位置座標 */
    getUserPosition(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        if ('geolocation' in navigator) {
          const options = {
            // enableHighAccuracy: false, //是否要以最高精準度來取得座標位置，預設為 false
            // timeout: 10000, // 必須要在多少時間內回應位置資訊，預設為 Infinity
            // maximumAge: 0, // 可以接受多少毫秒以前的暫存位置，預設為 0
          }
          navigator.geolocation.getCurrentPosition(
            // 取得位置成功
            position => {
              const longitude = position.coords.longitude
              const latitude = position.coords.latitude
              this.userPosition = {
                latitude: latitude,
                longitude: longitude,
              }
              this.hideLoading()
              resolve()
            },
            // 取得位置失敗
            error => {
              this.hideLoading()
              console.log('取得位置失敗', error.code, error.message)
              reject()
            },
            options
          )
        } else {
          this.hideLoading()
          console.log('不支援取得座標位置功能')
          reject()
        }
      })
    },
    /** 取得Poi清單1 - 重置選擇區域 */
    getPoiListByCatogory() {
      if (this.selectedArea.id >= 0) {
        this.showLoading()
        // 有指定區域，則地圖重置取該區域中心點與短邊座標
        // const selectedArea = { ...this.selectedArea }
        this.selectedArea = {
          name: '選擇區域',
          id: -1,
          zoomLevel: 9.5,
          latitude: 0,
          longitude: 0,
        }
        // this.selectedArea = selectedArea
        setTimeout(() => {
          this.handlePoiListByCatogory()
          this.hideLoading()
          return
        }, 1000)
      } else {
        this.handlePoiListByCatogory()
      }
    },
    /** 取得Poi清單2 - 分類 + 座標 */
    handlePoiListByCatogory() {
      // 如果地圖正在縮放中，則延遲處理
      if (this.mapChanging) {
        setTimeout(() => {
          this.handlePoiListByCatogory()
        }, 100)
        return
      }
      const params = {
        categoryId: this.categoryList[this.selectedCategoryIndex]
          ? this.categoryList[this.selectedCategoryIndex].id
          : '',
        centerLongitude: this.mapCenterPositon.longitude,
        centerLatitude: this.mapCenterPositon.latitude,
        boundaryLongitude: this.mapBoundaryPosition.longitude,
        boundaryLatitude: this.mapBoundaryPosition.latitude,
        displayMode: this.displayMode,
      }

      this.apiGetLocationByMap(params).then(res => {
        if (this.showMap && res.data.result.length == 0) {
          const msg = this.isSelectedPlaylist
            ? '清單中還沒有景點哦'
            : '找不到地點哦！'
          ElMessage({
            customClass: 'custom-toast',
            message: msg,
            type: 'warning',
            duration: 1500, // 1.5秒後消失
            offset: 82, // 距離網頁頂部（px）
            dangerouslyUseHTMLString: true,
          })
        }
      })
    },
    /** 取得Poi清單 - 關鍵字 + 座標 */
    getPoiListByKeyword() {
      // 如為地圖模式
      const position = this.mapCenterPositon
      const params = {
        keyword: this.searckKeyword,
        centerLongitude: position.longitude,
        centerLatitude: position.latitude,
      }
      console.log('搜尋關鍵字', params)
      this.apiGetLocationByKeyword(params)
      // 關鍵字模式搜尋後，區域統一顯示為"選擇區域"
      this.selectedArea = {
        name: '選擇區域',
        id: -1,
        zoomLevel: 9.5,
        latitude: 0,
        longitude: 0,
      }
    },
    /** 分類清單修改 */
    changeCategoryList(list: any[]) {
      // console.log('API: 修改分類清單')
      this.categoryList = Array.from(list)
      // 修改成功後，重新取得分類清單
      this.apiGetMemberPoiCategory()
      this.apiGetAllPoiCategory(1)
    },
    /** 設定登入狀態 */
    toggleIsLogin(status: boolean): void {
      this.isLogin = status
    },
    /** 設定會員 id */
    setMemberId(id: string): void {
      this.memberId = id
    },
    /** 登入狀態改變後，需要更新的資料 */
    updateMemberData() {
      this.apiGetMemberPoiCategory()
    },
    /** 清空登入資料 */
    clearLoginData() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('memberId')
      this.memberId = ''
      this.isLogin = false
    },
    /** 登出 */
    logout(showToast: boolean = true) {
      this.clearLoginData()
      this.updateMemberData()
      if (showToast) {
        ElMessage({
          customClass: 'custom-toast',
          message: '已登出',
          type: 'success',
          duration: 1500, // 1.5秒後消失
          offset: 82, // 距離網頁頂部（px）
        })
      }

      setTimeout(() => {
        window.location.href = './'
      }, 1500)
    },
    // 重新登入
    relogin() {
      this.clearLoginData()
      this.updateMemberData()
      this.toggleLoginDialog(true)
    },

    /** 設置 地圖中心點座標 MapCenterPositon */
    setMapCenterPositon(position: { latitude: number; longitude: number }) {
      this.mapBoundaryPosition = {
        latitude: 0,
        longitude: 0,
      }
      this.mapCenterPositon = position
    },
    /** API: 座標取得使用者位置資訊 */
    async getuserCurrentLocationInfo(): Promise<void> {
      return new Promise((resolve, reject) => {
        // console.log(
        //   'API: 座標取得使用者位置資訊, Location/GetLocationInfoByCoordinate',
        //   this.userPosition
        // )
        if (true) {
          // 成功取得使用者位置資訊後...
          this.userCurrentLocation = { ...this.defaultLocation }
        } else {
          // 取得使用者位置資訊失敗時，給預設位置
          this.userCurrentLocation = { ...this.defaultLocation }
        }
        resolve()
      })
    },
    /** API: 01.登入 - 第三方登入 */
    clientSignIn(params: {
      intent: string
      accessToken: string
      refreshToken: string
      thirdPartyUserId: string
      payload: string
    }): Promise<void> {
      return new Promise(async (resolve, reject) => {
        // 沒有父視窗，自己處理登入
        const timeStamp = getUtcTimeStamp(5)
        const tokenParams = {
          ...params,
          expireTimestamp: timeStamp,
        }
        const sendParams = {
          ...tokenParams,
          token: await this.getToken(tokenParams),
        }

        const res = await this.apiLogin(sendParams);
        const paramObj = params;

        if (res.apiStatus == '001') {
          console.log(res);
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          localStorage.setItem('memberId', res.data.memberId);

          const parsedWords = CryptoJS.enc.Base64.parse(paramObj.payload);
          const payloadData = JSON.parse(parsedWords.toString(CryptoJS.enc.Utf8));

          await this.apiUpdateRegistPersonalInfo({
            email: payloadData.email,
            gender: payloadData.gender,
            birthday: payloadData.birthDay,
            name: payloadData.name,
            phoneNumber: payloadData.mobilePhone,
            phoneCountryCode: payloadData.countryCode,
          }).then(() => {
            window.location.href = "https://uatweb.chictrip.com.tw";
          });
        }
        //this.isLogin = true;
        //this.memberId = params.memberId;
        //this.updateMemberData();
        resolve();
      })
    },

    async getToken(data: {
      expireTimestamp: Number
      thirdPartyLoginCompany: string
      name?: string
      thirdPartyUserId: string
      email?: string
    }): Promise<any> {
      return new Promise((resolve, reject) => {
        const name = data.name ? data.name : ''
        const email = data.email ? data.email : ''
        const tokenString = `${data.expireTimestamp}:${data.thirdPartyLoginCompany}:${name}:${data.thirdPartyUserId}:${email}:${this.loginSalt}`
        const token = SHA512(tokenString).toString()
        resolve(token);
      });
    },

    /** API: 01.登入 - 第三方登入 */
    apiLogin(params: {
      thirdPartyLoginCompany: string
      thirdPartyUserId: string
      email?: string
      name?: string
      expireTimestamp: number
      token: string
      AccessToken?: string
      RefreshToken?: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        // console.log('api: PostLoginThirdParty', params)
        // TODO: api以下兩個值應改為可不傳
        params.deviceToken = 'web'
        params.fcmToken = 'web'
        this.showLoading()
        api
          .PostLoginThirdParty(params)
          .then(response => {
            if (response.data.apiStatus == '001') {
              const data = response.data.data
              localStorage.setItem('accessToken', data.accessToken)
              localStorage.setItem('refreshToken', data.refreshToken)
              localStorage.setItem('memberId', data.memberId)
              this.isLogin = true
              this.memberId = data.memberId
              this.updateMemberData()
            }
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 01.登入 - Apple登入 */
    apiPostAppleLogin(params: {
      code1: string
      code2: string
      state: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        params.deviceToken = 'web'
        params.fcmToken = 'web'
        this.showLoading()
        api
          .PostAppleLogin(params)
          .then(response => {
            if (response.data.apiStatus == '001') {
              const data = response.data.data
              localStorage.setItem('accessToken', data.accessToken)
              localStorage.setItem('refreshToken', data.refreshToken)
              localStorage.setItem('memberId', data.memberId)
              this.isLogin = true
              this.memberId = data.memberId
              this.updateMemberData()
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
        this.hideLoading()
      })
    },
    /** API: 01.登入 - 取得Config */
    apiGetConfig(): Promise<void> {
      return new Promise((resolve, reject) => {
        // this.showLoading()
        api
          .GetConfig()
          .then(response => {
            if (response.data.apiStatus == '001') {
              console.log('apiGetConfig', response)
              this.hotaiMallUrl = response.data.data.dataVersion.hotaiMallUrl
              this.hotaiInsurancelUrl =
                response.data.data.dataVersion.hotaiInsurancelUrl
              this.agodaUrl = response.data.data.dataVersion.agodaUrl
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 全部的POI類別 */
    apiGetAllPoiCategory(page: number): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetAllPoiCategory({ page: page })
          .then(response => {
            if (response.data.apiStatus == '001') {
              console.log('GetAllPoiCategoryr', response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 貢獻影音自訂地點，取得自訂景點的POI類別 */
    apiGetCustomPoiCategory(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetCustomPoiCategory()
          .then(response => {
            if (response.data.apiStatus == '001') {
              // console.log('GetCustomPoiCategory', response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 會員的POI類別 */
    async apiGetMemberPoiCategory(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetMemberPoiCategory()
          .then(response => {
            if (response.data.apiStatus == '001') {
              // console.log('GetMemberPoiCategory', response)
              this.categoryList = response.data.data
              console.log('會員的POI類別::', this.categoryList)
              // 取得成功後，重置被選擇的分類為第一個
              this.selectCategory(0)

              // 取得[收藏]的標籤資料
              this.findCollectioIndex(response.data.data)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 更新會員的POI類別 */
    async apiUpdateMemberPoiCategory(params: {
      poiClassificationList: Array<any>
    }): Promise<void> {
      // console.log('params', params)
      return new Promise((resolve, reject) => {
        api
          .UpdateMemberPoiCategory(params)
          .then(response => {
            if (response.data.apiStatus == '001') {
              console.log('UpdateMemberPoiCategory', response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 取得全部區域資料 */
    async apiGetAllLocation(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetAllLocation()
          .then(response => {
            if (response.data.apiStatus == '001') {
              // console.log('GetAllLocation', response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 取得熱門目的地 */
    async apiGetPopularDestination(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetPopularDestination()
          .then(response => {
            if (response.data.apiStatus == '001') {
              // console.log('GetPopularDestination', response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 地圖模式 */
    async apiGetLocationByMap(
      params: {
        categoryId: string
        centerLongitude: number
        centerLatitude: number
        boundaryLongitude: number
        boundaryLatitude: number
        displayMode: string
      },
      isIndexMap = true
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        // 首次進入POI列表(收藏)
        if (this.isSelectedPlaylist) {
          const params = {
            playlistId: this.playlistActiveID,
          }
          this.showLoading()
          this.apiGetPlaylist(params)
            .then(response => {
              // 首頁第一次載入判斷
              if (!this.firstLoadingDone) {
                this.firstLoadingDone = true
                // 是否允許顯示loading （需求變動，故註解以下內容）
                this.allowShowLoading = true
              }

              resolve(response.data?.pois ? response.data.pois : [])
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              this.hideLoading()
            })
        }
        // 首次進入POI列表(其他POI)
        else {
          const otherParams = {
            countryId: 0,
            cityId: 0,
            areaId: 0,
            searchMode: 'location',
          }
          params = { ...otherParams, ...params }
          this.showLoading()
          api
            .GetLocationByMap({ ...params })
            .then(response => {
              if (response.data.apiStatus == '001') {
                if (isIndexMap) {
                  this.poiList = response.data.data.result
                }

                // 首頁第一次載入判斷
                if (!this.firstLoadingDone) {
                  this.firstLoadingDone = true
                  // 是否允許顯示loading （需求變動，故註解以下內容）
                  this.allowShowLoading = true
                }
              }
              resolve(response.data)
              this.hideLoading()
            })
            .catch(error => {
              reject(error)
              this.hideLoading()
            })
        }
      })
    },
    /** API: 02. 地圖 - 關鍵字搜尋Poi */
    async apiGetLocationByKeyword(params: {
      keyword: string
      centerLongitude: number
      centerLatitude: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetLocationByKeyword({ ...params })
          .then(response => {
            if (response.data.apiStatus == '001') {
              // console.log('GetLocationByMap', response)
              // console.log(
              //   'response.data.data.result',
              //   response.data.data.result
              // )
              this.poiList = response.data.data.result
              if (this.showMap && this.poiList.length == 0) {
                const msg = this.isSelectedPlaylist
                  ? '清單中還沒有景點哦'
                  : '找不到地點哦！'

                ElMessage({
                  customClass: 'custom-toast',
                  message: msg,
                  type: 'warning',
                  duration: 1500, // 1.5秒後消失
                  offset: 82, // 距離網頁頂部（px）
                  dangerouslyUseHTMLString: true,
                })
              }
            }
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 02. 地圖 - 景點明細 */
    async apiGetPoibyId(params: {
      id: string
      focusMediaId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetPoiById(params)
          .then(response => {
            // if (response.data.apiStatus == '001') {
            //   console.log('GetPoiById::', response.data.data)
            // }
            resolve(response.data)
            this.hideLoading()
            this.apiPoiData = { ...response.data.data }
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 02. 地圖 - 景點最愛 */
    async apiPostFavorite(params: {
      poiId: string
      isfavorite: boolean
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostFavorite(params)
          .then(response => {
            // if (response.data.apiStatus == '001') {
            //   console.log('PostFavorite::', response)
            // }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 02. 地圖 - 素材點讚 */
    async apiPostMediaLike(params: {
      mediaId: string
      islike: boolean
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostMediaLike(params)
          .then(response => {
            // if (response.data.apiStatus == '001') {
            //   console.log('PostMediaLike::', response)
            // }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 07. 會員中心 - 取得會員中心資訊 */
    async apiGetMemberInfo(params: { targetMemberId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetMemberInfo(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 取得會員照片列表 分頁 */
    async apiGetPhotoListPaging(params: {
      targetMemberId: string
      page?: number // 預設1
      lastMediaId?: string // 第一頁 沒有 lastMediaId 可以不帶 或 輸入 00000000-0000-0000-0000-000000000000
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetPhotoListPaging(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 取得會員影片列表 分頁 */
    async apiGetFilmListPaging(params: {
      targetMemberId: string
      page?: number // 預設1
      lastMediaId?: string // 第一頁 沒有 lastMediaId 可以不帶 或 輸入 00000000-0000-0000-0000-000000000000
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetFilmListPaging(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 追蹤會員 */
    async apiPostFollowMember(params: {
      targetMemberId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .FollowMember(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 取消追蹤會員 */
    async apiDelUnFollowMember(params: {
      targetMemberId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .UnFollowMember(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 綁定和泰會員 */
    async apiBindHotai(params: {
      hotaiOneId: string
      accessToken: string
      refreshToken: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .BindHotai(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 更新素材權限和連結 */
    async apiUpdateMedia(params: {
      MediaId: string
      ArticleUrl: string
      Authority: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .UpdateMedia(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 刪除素材 */
    async apiDeleteMedia(params: { MediaId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .DeleteMedia(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 取得更新會員資料 */
    async apiGetUpdateMemberInfo(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetUpdateMemberInfo({})
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 修改會員資料 */
    async apiUpdateMember(params: {
      Name: string
      Introduction: string
      PhoneNumber: string
      Email: string
      Gender: string
      Birthday: string
      MainLink: string
      Facebook: string
      Instagram: string
      Twitter: string
      Xiaohongshu: string
      Tiktok: string
      Youtube: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .UpdateMember(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 修改會員中心照片 Base64 */
    async apiUpdateMemberPhotoBase64(params: {
      Width: string
      Height: string
      FileName: string
      Base64: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .UpdateMemberPhotoBase64(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 07. 會員中心 - 刪除會員 */
    async apiDeleteMember(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .DeleteMember({})
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 08. 行程細節 - 行程TSD列表 */
    async ActionTravelScheduleDetail(params: {
      travelScheduleId: string
      TravelScheduleUpdateTime?: number
      isMyTravelSchedule: number
      language?: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        // console.log('api: GetTravelScheduleDetail', params)
        api
          .GetTravelScheduleDetail(params)
          .then(response => {
            if (response.data.apiStatus == '001' && response.data.data) {
              this.travelScheduleDetail = response.data.data

              this.handleTSDPermission(
                this.travelScheduleDetail.travelScheduleInfo.permission
              )
            } else {
              this.travelScheduleDetail = {
                travelScheduleInfo: {},
                dayList: [],
              }
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 預覽狀態設定
    setPreViewStatus(status: boolean) {
      this.isPreView = status
    },

    // [預覽收藏]-狀態設定
    setPlaylistPreViewStatus(status: boolean, preViewID?: string) {
      this.isPreViewPlaylist = status

      if (status) {
        // 關閉TSD
        this.toggleTravel(false)
        this.toggleScheduleDetail(false)
      }

      if (preViewID) {
        // this.playlistActiveID = preViewID
        const params = {
          playlistId: preViewID,
        }

        this.apiGetPlaylist(params)
          .then((res: any) => {
            if (res.apiStatus === '001') {
              this.playlistActiveID = preViewID
              this.preViewPlaylistErr = false
            } else {
              this.preViewPlaylistErr = true
            }
          })
          .catch(err => {
            this.preViewPlaylistErr = true
          })
          .finally(() => { })
      }
    },

    // 管理行程權限判斷[預覽：PreView 自己：Owner 可編輯：Editor 檢視：Viewer]
    handleTSDPermission(type: string) {
      const query = new URLSearchParams(window.location.search)
      const action = query.get('action')
      const preViewTravelId = query.get('preViewTravelId')

      // 判斷是否為預覽模式
      if (action == 'preView' || preViewTravelId) {
        this.isPreView = true
      } else {
        this.isPreView = false
      }

      // 如果是預覽模式就不更換權限狀態
      if (this.isPreView) {
        this.TSDpermission = 'PreView'
      } else {
        this.TSDpermission = type
      }

      // 判斷有無編輯權限
      if (!this.isLogin) {
        // 未登入無編輯權限
        this.hasEditTSDPermission = false
      } else if ((!this.isPreView && type == 'Owner') || type == 'Editor') {
        this.hasEditTSDPermission = true
      } else {
        this.hasEditTSDPermission = false
      }

      console.log('瀏覽身份 TSDpermission::', this.TSDpermission)
      console.log('編輯權限 hasEditTSDPermission::', this.hasEditTSDPermission)
      console.log('預覽狀態 isPreView::', this.isPreView)
    },

    /** API: 08. 行程細節 - TSD明細 */
    async apiGetTSDDetail(params: {
      tsdId: string
      travelScheduleId: string
      TravelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetTSDDetail(params)
          .then(response => {
            if (response.data.apiStatus == '001') {
              console.log('GetTSDDetail::', response.data)
            }
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 08. 行程細節 - 設定TSD封面 */
    async apiPutSetCover(params: {
      tsdId: string
      MediaId: string
      travelScheduleId: string
      TravelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutSetCover(params)
          .then(response => {
            if (response.data.apiStatus == '001') {
              console.log('PutSetCover::', response.data)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 開啟/ 收合 Header
    toggleHeader(header: boolean) {
      this.handleHeader = header
    },
    // 開啟/ 收合 側選單
    toggleMenu(menu: Boolean): void {
      // 防止 safari 側選單抖動
      clearTimeout(this.debounceTimer)
      this.debounceTimer = null
      if (menu) {
        this.$state.isCollapse = false
      } else {
        this.debounceTimer = setTimeout(() => {
          this.$state.isCollapse = true
        }, 200)
      }
    },
    /** 選擇分類 */
    selectCategory(index: number) {
      this.selectedCategoryIndex = index
    },

    /** 取得收藏的index */
    findCollectioIndex(list: any) {
      const index = list.findIndex(item => item.icon === 'heart')
      this.collectioIndex = index
    },

    /** 選擇關鍵字類型 */
    selectKeywordType(id: string) {
      this.selectedKeywordType = id
    },
    /** 開啟/ 關閉 關鍵字模式 */
    toggleKeywordMode(show: boolean) {
      this.keywordMode = show
    },

    // 是/否 為搜尋此區域
    toggleIsSearchAround(status: boolean) {
      this.isSearchAround = status
    },

    /** 搜尋關鍵字 */
    changeKeyword(keyword: string) {
      this.searckKeyword = keyword
      if (this.searckKeyword) {
        this.getPoiListByKeyword()
      }
    },
    /** 開啟/ 收合 行程列表*/
    toggleTravel(show: boolean) {
      this.showTravel = show
    },
    /** 開啟/ 收合 地圖模式 */
    toggleMapMode(show?: boolean) {
      if (show != undefined) {
        this.showMap = show
      } else {
        this.showMap = !this.showMap
      }
    },

    // 地圖載入完畢狀態
    setMapReady() {
      this.mapReady = true
    },
    /** 開啟/ 收合 我的分類彈窗 */
    toggleCategoryDialog(show: boolean) {
      this.showCategoryDialog = show
    },
    /** 開啟/ 收合 地圖模式 POI 列表彈窗 */
    togglePoiList(show?: boolean) {
      if (show != undefined) {
        this.showPoiListDialog = show
      } else {
        this.showPoiListDialog = !this.showPoiListDialog
      }
    },
    /** 開啟/ 收合 POI 加入行程彈窗 */
    toggleAddPoiTsd(show: boolean, poi?: object) {
      this.showAddPoiTsd = show
      this.poiAddTsdContent = poi
    },
    /** 開啟/ 收合多個 POI 加入行程彈窗 */
    toggleAddPoisTsd(show: boolean) {
      this.showAddPoisTsd = show
    },
    /** 開啟/ 收合 POI 上傳影音彈窗 */
    toggleAddMedia(
      show: boolean,
      placeType = 'none',
      customPoiPlaceData?: any,
      customMarker?: any
    ) {
      this.uploadMediaData.placeType = placeType
      if (placeType == 'custom') {
        this.uploadMediaData.customPoiPlaceData = customPoiPlaceData
        this.uploadMediaData.customMarker = customMarker
      }

      this.showAddMedia = show
    },
    /** 開啟/ 關閉交通方案 & 行程細節帶入交通資訊 */
    toggleTrafficSetting(show: boolean, trafficSettingData?: any) {
      this.showTrafficSetting = show
      this.trafficSettingData = trafficSettingData
    },
    /** 開啟/ 收合 POI詳細資訊彈窗(與TSD詳細資訊彈窗同一個) */
    togglePoiDialog(show: boolean, poiItem?: any) {
      this.poiDialogIsTSD = false
      if (!this.isPreView) {
        this.handlePoiUrlParams(show, poiItem)
      }
      this.showPoiDialog = show
      // app 下載bar層級控制
      this.handleAppDownloadTop(show)
    },

    /** Poi 開/關 控制url判斷 */
    async handlePoiUrlParams(show: boolean, poiItem?: any) {
      const queryParams = new URLSearchParams(window.location.search)
      const actionValue = queryParams.get('action')
      // console.log('poiItem', poiItem)

      if (show) {
        this.poiDialogSetting.poiId = poiItem.id || ''
        this.poiDialogSetting.mediaId =
          poiItem.media && poiItem.media.length > 0 ? poiItem.media[0].id : ''
        // 會員頁則不修改url
        if (actionValue != 'profile') {
          const res = await this.apiGetSeoPoiDetail({ poiId: poiItem.id })
          if (res.apiStatus == '001') {
            document.title = res.data.title
          }
          addUrlParams({
            action: 'poiInfo',
            poiId: poiItem.id,
          })
        }
        // document.title = poiItem.name
      } else {
        this.poiDialogSetting.poiId = ''
        this.poiDialogSetting.mediaId = ''
        // 會員頁則不修改url
        if (actionValue != 'profile') {
          deleteUrlParams(['action', 'poiId'])
        }
        document.title = this.homePageTitle
      }
    },

    /** 開啟/ 收合 TSD詳細資訊彈窗(與POI詳細資訊彈窗同一個) */
    toggleTSDDialog(
      show: boolean,
      tsdId: string,
      travelScheduleId: string,
      TravelScheduleUpdateTime: number,
      openMedia?: boolean,
      permission?: string,
      poiId?: string
    ) {
      // console.log('開啟TSD', show, tsdId)
      this.poiDialogIsTSD = true
      if (show) {
        this.tsdDialogSetting = {
          tsdId: tsdId || '',
          travelScheduleId: travelScheduleId || '',
          TravelScheduleUpdateTime: TravelScheduleUpdateTime || 0,
          openMedia: openMedia || false,
          permission: permission || '',
          poiId: poiId || '',
        }
      } else {
        this.tsdDialogSetting = {
          tsdId: '',
          travelScheduleId: '',
          TravelScheduleUpdateTime: 0,
          openMedia: openMedia || false,
          permission: permission || '',
          poiId: '',
        }
      }
      this.showPoiDialog = show
      this.handleAppDownloadTop(show)
    },
    // TSD詳細資訊彈窗 資訊設定
    setTsdDialogSetting(data: any) {
      this.tsdDialogSetting = {
        tsdId: data.tsdId,
        travelScheduleId: data.travelScheduleId,
        TravelScheduleUpdateTime: data.TravelScheduleUpdateTime,
        openMedia: data.openMedia || false,
        permission: data.permission || '',
        poiId: data.poiId || '',
      }
    },
    /** 開啟/ 收合 影音設定彈窗 */
    toggleMediaSettingDialog(show: boolean) {
      this.showMediaSettingDialog = show
    },

    /** 開啟/ 收合 選擇區域彈窗 */
    toggleAreaSettingDialog(show: boolean) {
      this.showAreaSettingDialog = show
    },
    /** 開啟/ 收合 登入彈窗 */
    toggleLoginDialog(show: boolean) {
      this.showLoginDialog = show
    },
    /** 更新 列表排序方式 */
    setDisplayMode(displayMode: string) {
      if (displayMode == this.displayMode) return
      this.displayMode = displayMode
      if (!this.keywordMode) {
        this.getPoiListByCatogory()
      }
    },
    /** 更新選擇區域 */
    changeSelectedArea(areaContent: any) {
      this.selectedArea = areaContent
    },
    /** 更改 釘選Poi（地圖中心位置設置為Poi)/
     * pin: 是否選釘poi
     * poiItem: 選定的poi內容
     * movePinPoi: 是否移動地圖將poi置為中心點
     * zoomInPinPoi: 是否放大地圖至15
     */
    changeMapIsPinPoi(
      pin: boolean,
      poiItem?: any,
      movePinPoi?: boolean,
      zoomInPinPoi?: boolean
    ): void {
      if (!pin) {
        this.zoomInPinPoi = false
        this.movePinPoi = false
      } else {
        this.movePinPoi = movePinPoi || false
        this.zoomInPinPoi = zoomInPinPoi || false
      }

      this.mapHoverPoi = null
      let poi
      // 地圖模式僅顯示相同名稱與座標的第一則貼文
      if (poiItem) {
        poi = toRaw(this.poiList).find(item => {
          return (
            item.name == poiItem.name &&
            item.latitude == poiItem.latitude &&
            item.longitude == poiItem.longitude
          )
        })
      }

      // 指定中心座標
      this.mapHoverPoi = poi || null
      // setTimeout(() => {
      this.mapIsPinPoi = pin && poi
      // }, 60)
    },

    /** 更改 釘選TSD（地圖中心位置設置為TSD)/
     * pin: 是否選釘tsd
     * tsdItem: 選定的tsd內容
     * movePinTSD: 是否移動地圖將tsd置為中心點
     * zoomInPinTSD: 是否放大地圖至15
     */
    changeMapIsPinTSD(
      pin: boolean,
      tsdItem?: any,
      movePinTSD?: boolean,
      zoomInPinTSD?: boolean
    ): void {
      if (!pin) {
        this.zoomInPinTSD = false
        this.movePinTSD = false
      } else {
        this.movePinTSD = movePinTSD || false
        this.zoomInPinTSD = zoomInPinTSD || false
      }

      // 指定中心座標
      this.mapHoverTSD = tsdItem || null
      this.mapIsPinTSD = pin && tsdItem
    },
    // isSearchThisArea
    setSearchThisAreaState(state: boolean) {
      this.isSearchThisArea = state
    },

    /** 更改 釘選收藏（地圖中心位置設置為收藏)/
     * pin: 是否選釘tsd
     * tsdItem: 選定的tsd內容
     * movePinTSD: 是否移動地圖將tsd置為中心點
     * zoomInPinTSD: 是否放大地圖至15
     */
    changeMapIsPinPlaylist(
      pin: boolean,
      playItem?: any,
      movePinPlaylist?: boolean,
      zoomInPinPlaylist?: boolean
    ): void {
      if (!pin) {
        this.zoomInPinPlaylist = false
        this.movePinPlaylist = false
      } else {
        this.movePinPlaylist = movePinPlaylist || false
        this.zoomInPinPlaylist = zoomInPinPlaylist || false
      }

      // 指定中心座標
      this.mapHoverPlaylist = playItem || null
      this.mapIsPinPlaylist = pin && playItem
    },

    /** 顯示載入中 */
    showLoading() {
      this.loadingCount++
    },
    /** 隱藏載入中 */
    hideLoading() {
      this.loadingCount = this.loadingCount > 0 ? this.loadingCount - 1 : 0
    },

    /** 強制隱藏載入中 */
    forceHideLoading() {
      this.loadingCount = 0
    },

    /** 更改是否為中小網 */
    changeIsMobile(isMobile: boolean) {
      this.isMobile = isMobile
    },
    /** 開啟/ 收合 加入共編彈窗 */
    toggleJoinCollaborative(show: boolean) {
      this.showJoinCollaborative = show
      this.handleAppDownloadTop(show)
    },
    /** 開啟/ 收合 行程設定 */
    toggleTravelScheduleSettings(show: boolean) {
      this.showTravelScheduleSettings = show
    },

    // 設定行程設定狀態 (true:行程設定 false:新增行程)
    setTravelScheduleSettingStatus(status: boolean) {
      this.isTravelScheduleSetting = status
    },

    /** 開啟/ 收合 設定Hashtag */
    toggleHashtagSettings(show: boolean) {
      this.showHashtagSettings = show
    },
    /** 開啟/ 收合 選擇Hashtag */
    toggleSelectHashtag(show: boolean, triggerEditAPI: boolean) {
      this.showSelectHashtag = {
        show: show,
        triggerEditAPI: triggerEditAPI, //觸發編輯api
      }
    },

    // 設定編輯標籤所選的id
    setSelectHashtagId(id: string) {
      this.selectHashtagId = id
      // this.defaulUserLabel
    },

    /** 行程 分享[share]/共編[collaboration] 設定 */
    setShareSchedule(id?: string, type?: string, showTab?: boolean) {
      this.shareSchedule.id = id ? id : ''
      this.shareSchedule.type = type ? type : 'share'
      this.shareSchedule.showTab = showTab ? true : false
    },

    setShareScheduleData(data: Object) {
      this.shareScheduleData = data
    },

    // 行程標籤 - 預設調整標籤
    setDefaulTravelScheduleUserLabel(data: Object) {
      this.defaulTravelScheduleUserLabel = data
    },

    // 行程標籤 - 預設標籤[未標籤]
    setDefaulUserLabel(data: string) {
      this.defaulUserLabel = data
    },

    /** 開啟/ 收合 行程細節 */
    toggleScheduleDetail(show: boolean) {
      this.showScheduleDetail = show
      if (!show) {
        this.toggleScheduleDetailIsMin(false)
      }
    },

    /** 展開/ 收合 行程細節 */
    toggleScheduleDetailIsMin(isMin: boolean) {
      this.isScheduleDetailMin = isMin
    },

    // 設定目前行程細節資料
    setshowScheduleDetailData(data: {
      travelScheduleId: string
      travelScheduleName: string
      TravelScheduleUpdateTime: string
      isMyTravelSchedule: number
    }) {
      this.showScheduleDetailData = {
        travelScheduleId: data.travelScheduleId,
        travelScheduleName: data.travelScheduleName,
        TravelScheduleUpdateTime: data.TravelScheduleUpdateTime,
        isMyTravelSchedule: data.isMyTravelSchedule,
      }
    },

    // 設定是否已離開共編
    setIsQuitCollaboration(status: boolean, name: string) {
      this.isQuitCollaboration = status
      this.quitCollaborationName = name
    },

    //  設定行程細節內容有更新-狀態
    setIsScheduleDetailUpdated(state: boolean) {
      this.isScheduleDetailUpdated = state
    },

    // 設定目前編輯筆記的TSD_ID
    setEditTSDNoteId(id: string) {
      this.editTSDNoteId = id
    },

    // 設定目前編輯的TSD
    setTsdIdToSetting(id: string) {
      this.tsdIdToSetting = id
    },

    // 設定目前編輯交通偏好的ID
    setTsdTrafficId(id: string) {
      this.tsdTrafficId = id
    },

    // 設定目行程工具設定
    setTripToolId(id: string) {
      this.tripToolId = id
    },

    // 設定當前顯示的天
    setTsdCurrentDay(day: number) {
      this.tsdCurrentDay = day
    },

    // 當前選擇交通方案
    setTrafficDetail(route: any) {
      this.currentTrafficDetail = route
      // console.log('畫交通路線用data', toRaw(this.currentTrafficDetail))
    },

    /** API: 02. 地圖 - 貢獻影音(影片) */
    async apiPostAddCustomPoi(params: any): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostAddCustomPoi(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 03. 行程標籤 - 取得會員的行程標籤列表 */
    async apiGetTravelScheduleUserLabel() {
      return new Promise((resolve, reject) => {
        this.showLoading()
        api
          .GetTravelScheduleUserLabel()
          .then(response => {
            if (response.data.apiStatus == '001') {
              this.travelScheduleUserLabel = response.data.data
            } else {
              this.travelScheduleUserLabel = []
            }
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 03. 行程標籤 - 新增會員的行程標籤 */
    async apiPostTravelScheduleUserLabelAdd(params: {
      name: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostTravelScheduleUserLabelAdd(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 03. 行程標籤 - 修改會員的行程標籤 */
    async apiPutUpdateTravelScheduleUserLabel(params: {
      id: string
      name: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateTravelScheduleUserLabel(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 03. 行程標籤 - 排序會員的行程標籤 */
    async apiPutSortTravelScheduleUserLabel(params: {
      idList: Array<string>
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutSortTravelScheduleUserLabel(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 03. 行程標籤 - 刪除會員的行程標籤 */
    async apiDeleteTravelScheduleUserLabel(params: {
      id: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .DeleteTravelScheduleUserLabel(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** 行程列表 - 排序 */
    SetMyScheduleSortStore(sort: string) {
      this.MyScheduleSortStore = sort
    },

    /** 共編列表 - 排序 */
    SetCollaborationSortStore(sort: string) {
      this.CollaborationSortStore = sort
    },

    /** API: 04.行程列表 - 取得行程列表 */
    /** [8/23] API 加入排序參數(orderByColumn,sort) */
    async ActionTravelScheduleList(
      params: {
        updateTime: number
        orderByColumn?: string
        sort?: string
        language?: string
      },
      forSort?: boolean
    ): Promise<{ apiStatus: string; data: any[] }> {
      return new Promise((resolve, reject) => {
        // this.showLoading()
        api
          .GetTravelScheduleList(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (!forSort) {
              if (response.data.apiStatus == '001' && response.data.data) {
                this.travelSchedule = response.data.data
              } else {
                this.travelSchedule = []
              }
            }
            resolve(response.data)
            // this.hideLoading()
          })
          .catch(error => {
            reject(error)
            // this.hideLoading()
          })
      })
    },
    /** API: 04.行程列表 - 取得行程列表包含細節（舊） */
    async ActionTravelScheduleWithDetail(params: {
      updateTime: number
      language?: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetTravelScheduleWithDetail(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001' && response.data.data) {
              console.log('api: 取得行程列表包含細節::', response.data)
            } else {
              this.travelScheduleWithDetail = [
                {
                  travelScheduleInfo: {},
                  dayList: [],
                },
              ]
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 04.行程列表 - 取得我的及共編行程列表 */
    async ActionMyAndCollaboration(
      params: {
        updateTime: number
        orderByColumn?: string
        sort?: string
        language?: string
      },
      forSort?: boolean
    ): Promise<{ apiStatus: string; data: any[] }> {
      console.log('取得新的[行程列表]')
      return new Promise((resolve, reject) => {
        // this.showLoading()
        api
          .GetMyAndCollaboration(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (!forSort) {
              if (response.data.apiStatus == '001' && response.data.data) {
                this.travelSchedule = response.data.data
              } else {
                this.travelSchedule = []
              }
            }
            resolve(response.data)
            // this.hideLoading()
          })
          .catch(error => {
            reject(error)
            // this.hideLoading()
          })
      })
    },

    /** API: 04.行程列表 - 取得我的及共編行程列表包含TSD數量 */
    async ActionMyAndCollaborationWithTsdCount(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetMyAndCollaborationWithTsdCount()
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)

            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 04.行程列表 - 取得系統行程封面圖列表 */
    async apiGetSystemCoverList(): Promise<void> {
      // this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetSystemCoverList()
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001') {
              this.systemCoverList = response.data.data
            } else {
              this.systemCoverList = []
            }
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 04.行程列表 - 新增行程 */
    async apiPostAddTravelSchedule(params: {
      CoverMediaId: string
      Name: string
      StartDate: string
      EndDate: string
      TotalDay: string
      ViewMode: string
      TravelScheduleUserLabelId: string
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostAddTravelSchedule(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 04.行程列表 - 刪除行程 */
    async apiDeleteTravelSchedule(params: { id: string }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .DeleteTravelSchedule(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 04.行程列表 - 複製行程 */
    async apiPostCopyTravelSchedule(params: {
      travelScheduleId: string
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostCopyTravelSchedule(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** 04.行程列表 - 修改行程 */
    async apiPutTravelScheduleUpdate(params: {
      id: string
      CoverMediaId: string
      Name: string
      StartDate: string
      EndDate: string
      TotalDay: number
      ViewMode: string
      TravelScheduleUserLabelId: string
      IsForceUpdateTsdRoute?: number
      TrafficType?: string | null
      updateTime: number | string
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PutTravelScheduleUpdate(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** 04.行程列表 - 會員修改行程封面圖 Base64 */
    async apiPutUpdateTravelScheduleCoverBase64(params: {
      id: string
      Width: number
      Height: number
      FileName: string
      Base64: string
      updateTime: number | string
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PutUpdateTravelScheduleCoverBase64(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** 04.行程列表 - 會員新增行程封面圖 Base64 */
    async apiPostUploadTravelScheduleCoverBase64(params: {
      Width: number
      Height: number
      FileName: string
      Base64: string
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostUploadTravelScheduleCoverBase64(params)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** 04.行程列表 - 取得行程筆記 */
    async apiGetTravelScheduleNote(params: {
      id: string
      updateTime: string | number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetTravelScheduleNote(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001') {
              this.tavelScheduleNote = response.data.data
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** 04.行程列表 - 修改行程筆記 */
    async apiPutUpdateTravelScheduleNote(params: {
      id: string
      note: string
      updateTime: string | number
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PutUpdateTravelScheduleNote(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** 04.行程列表 - 行程刪除某一天 */
    async apiPutDeleteDeleteDay(params: {
      id: string
      DeleteDay: number
      StartDate: string
      EndDate: string
      TotalDay: number
      updateTime: string | number
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .DeleteDeleteDay(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 05.DeepLink + Log - 產生 景點明細頁 DeepLink */
    async createPoiInfoDeepLink(params: {
      poiId: string
      isOpenAppStore: number
    }): Promise<void> {
      this.showLoading()
      const extendedParams = {
        ...params,
        isOpenAppStore: params?.isOpenAppStore ?? 1,
      }
      return new Promise((resolve, reject) => {
        api
          .PostCreatePoiInfoDeepLink(extendedParams)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 05.DeepLink + Log - 預覽行程 DeepLink */
    async createPreViewDeepLink(params: {
      travelScheduleId: string
      isOpenAppStore: number
    }): Promise<void> {
      this.showLoading()
      const extendedParams = {
        ...params,
        isOpenAppStore: params?.isOpenAppStore ?? 1,
      }
      return new Promise((resolve, reject) => {
        api
          .PostCreatePreViewDeepLink(extendedParams)
          .then(response => {
            if (response.data.apiStatus == '001') {
              this.preViewDeepLink = response.data.data
            } else {
              this.preViewDeepLink = ''
            }
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 05.DeepLink + Log - 邀請共編頁 DeepLink */
    async createCollaborationDeepLink(params: {
      travelScheduleId: string
      permission: string
      isOpenAppStore: number
    }): Promise<void> {
      this.showLoading()
      const extendedParams = {
        ...params,
        isOpenAppStore: params?.isOpenAppStore ?? 1,
      }
      return new Promise((resolve, reject) => {
        api
          .PostCreateCollaborationDeepLink(extendedParams)
          .then(response => {
            if (response.data.apiStatus == '001') {
              this.collaborationDeepLin = response.data.data
            } else {
              this.collaborationDeepLin = ''
            }
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },
    /** API: 05.DeepLink + Log - 產生 個人資訊頁面 DeepLink */
    async apiCreateProfileDeepLink(params: {
      memberId: string
      isOpenAppStore: number
    }): Promise<void> {
      this.showLoading()
      const extendedParams = {
        ...params,
        isOpenAppStore: params?.isOpenAppStore ?? 1,
      }
      return new Promise((resolve, reject) => {
        api
          .PostCreateProfileDeepLink(extendedParams)
          .then(response => {
            resolve(response.data)
            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** API: 06. 行程共編和分享列表 */
    async ActionCollaborationList(
      params: {
        updateTime: number
        orderByColumn?: string
        sort?: string
        language?: string
      },
      forSort?: boolean
    ): Promise<{ apiStatus: string; data: any[] }> {
      // this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetCollaborationList(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (!forSort) {
              if (response.data.apiStatus == '001' && response.data.data) {
                this.travelScheduleCollaboration = response.data.data
              } else {
                this.travelScheduleCollaboration = []
              }
            }
            resolve(response.data)
            // this.hideLoading()
          })
          .catch(error => {
            reject(error)
            // this.hideLoading()
          })
      })
    },
    /** API: 06. 行程共編和分享 - 取得與我共編的行程包含細節 */
    async ActionCollaborationWithDetail(params: {
      updateTime: number
      language?: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetCollaborationWithDetail(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001' && response.data.data) {
              this.collaborationWithDetail = response.data.data
              console.log(
                'api: GetCollaborationWithDetail',
                this.collaborationWithDetail
              )
            } else {
              this.collaborationWithDetail = [
                {
                  travelScheduleInfo: {},
                  dayList: [],
                },
              ]
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 取得行程分享連結 */
    async apiGetTravelScheduleShareLink(params: {
      travelScheduleId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetTravelScheduleShareLink(params)
          .then(response => {
            if (response.data.apiStatus == '001' && response.data.data) {
              this.travelScheduleShareLink = response.data.data
            }

            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 06. 分享 - 取得行程共編分享連結 */
    async apiGetCollaborationLink(params: {
      travelScheduleId: string
      permission: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetCollaborationLink(params)
          .then(response => {
            if (response.data.apiStatus == '001' && response.data.data) {
              this.collaborationText = response.data.data.content
            }

            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 取得行程分享文字檔 */
    async apiGetTravelScheduleShareText(params: {
      travelScheduleId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetTravelScheduleShareText(params)
          .then(response => {
            if (response.data.apiStatus == '001' && response.data.data) {
              this.travelScheduleShareText = response.data.data
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 發送分享行程Email */
    async apiPostTravelScheduleShareSendEmail(params: {
      travelScheduleId: string
      email: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostTravelScheduleShareSendEmail(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 新增行程共同編輯會員 */
    async apiPostAddTravelScheduleCollaboration(params: {
      travelScheduleId: string
      permission: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostAddTravelScheduleCollaboration(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 新增行程共同編輯會員[WithToken] */
    async apiPostAddTravelScheduleCollaborationWithToken(params: {
      travelScheduleId: string
      permission: string
      timeStamp: number
      token: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostAddTravelScheduleCollaborationWithToken(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 取得行程共同編輯列表[人員] */
    async apiGetTravelScheduleCollaboration(params: {
      travelScheduleId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetTravelScheduleCollaboration(params)
          .then(response => {
            if (response.data.apiStatus == '001' && response.data.data) {
              this.travelScheduleCollaborationData = response.data.data
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 取得行程共同編輯列表[WithToken] */
    async apiGetTravelScheduleCollaborationWithToken(params: {
      travelScheduleId: string
      permission: string
      timeStamp: number
      token: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetTravelScheduleCollaborationWithToken(params)
          .then(response => {
            if (response.data.apiStatus == '001' && response.data.data) {
              this.travelScheduleCollaborationWithTokenData = response.data.data
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 擁有者修改行程共同編輯權限 */
    async apiPutUpdatePermissionByOwner(params: {
      travelScheduleId: string
      memberId: string
      permission: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdatePermissionByOwner(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 自己退出行程共同編輯 */
    async apiDeleteQuitTravelScheduleCollaboration(params: {
      travelScheduleId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .DeleteQuitTravelScheduleCollaboration(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 06. 行程共編和分享 - 擁有者刪除行程共同編輯會員 */
    async apiDeleteByOwner(params: {
      travelScheduleId: string
      memberId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .DeleteByOwner(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 08. 行程細節 - 加入TSD最推薦是第幾天 */
    async ActionAddWhereBestAll(params: {
      PoiId: string
      TravelScheduleId: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetAddWhereBestAll(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 08. 行程細節 - 查詢TSD要放在哪裡 */
    async ActionAddWhere(params: {
      poiId: string
      travelScheduleId: number
      travelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetAddWhere(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 08. 行程細節 - 新增TSD */
    async ActionAddTsd(params: {
      TravelScheduleId: string
      Day: number | string
      PoiId: string
      AddWhereId: string
      StayTime: number
      ArrivalTrafficType: string
      DepartureTrafficType: string
      TravelScheduleUpdateTime: number
      TsdCoverMediaId: string
      TsdName: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostAddTsd(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 08. 行程細節 - 收藏 POI 批次轉 TSD */
    async ActionAddTsdByFavoritePoi(params: {
      travelScheduleId: string
      travelScheduleUpdateTime: number
      day: number
      PoiIdList?: string[]
      PlaylistId?: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostAddByFavoritePoi(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 08. 行程細節 - 複製TSD */
    async ActionCopyTsd(params: {
      TravelScheduleId: string
      CopyDay: number | string
      CopyTsdId: string
      StayTime: number
      ArrivalTrafficType: string
      TravelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostCopyTsd(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 08. 行程細節 - 刪除TSD */
    async DeleteTsd(params: {
      TravelScheduleId: string
      Day: number | string
      TsdId: string
      TravelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .DeleteTsd(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 08. 取得 TSD 筆記 */
    async getTSDNote(params: {
      TravelScheduleId: string
      TsdId: string
      TravelScheduleUpdateTime: number
    }): Promise<void> {
      // this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetTSDNote(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            this.hideLoading()
            resolve(response.data)
          })
          .catch(error => {
            this.hideLoading()
            reject(error)
          })
      })
    },

    /** API: 08. 更新 TSD 筆記 */
    async putTSDNote(params: {
      TravelScheduleId: string
      TsdId: string
      TravelScheduleUpdateTime: number
      Note: string
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PutTSDNote(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001') {
              console.log('更新 TSD 筆記::', response)
            }
            this.hideLoading()
            resolve(response.data)
          })
          .catch(error => {
            this.hideLoading()
            reject(error)
          })
      })
    },

    /** API: 08. 取得編輯TSD資訊 */
    async getEditInfo(params: {
      TsdId: string
      TravelScheduleId: string
      travelScheduleUpdateTime: number
    }): Promise<void> {
      // this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetEditInfo(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001') {
              // console.log('取得編輯TSD資訊::', response)
            }
            this.hideLoading()
            resolve(response.data)
          })
          .catch(error => {
            this.hideLoading()
            reject(error)
          })
      })
    },

    /** API: 08. 編輯TSD */
    async putUpdateInfo(params: {
      TsdId: string
      Name: string
      PoiClassificationId: string
      StayTime: number
      IsUseCustomArrivalTime: number
      CustomArrivalTime: string
      IsUseCustomDepartureTime: number
      CustomDepartureTime: string
      TravelScheduleId: string
      travelScheduleUpdateTime: number
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PutUpdateInfo(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001') {
              console.log('編輯TSD::', response)
            }
            this.hideLoading()
            resolve(response.data)
          })
          .catch(error => {
            this.hideLoading()
            reject(error)
          })
      })
    },

    /** API: 08. 排序TSD */
    async putTsdSort(params: {
      TravelScheduleId: string
      MoveOutDay: number
      MoveInDay: number
      MoveTsdId: string
      TsdIdList: Array<any>
      travelScheduleUpdateTime: number
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PutTsdSort(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            this.hideLoading()
            resolve(response.data)
          })
          .catch(error => {
            this.hideLoading()
            reject(error)
          })
      })
    },

    /** API: 08. 檢查行程更新時間 */
    async apiVerifyUpdateTime(params: {
      TravelScheduleId: string
      travelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetVerifyUpdateTime(params)
          .then(response => {
            // this.checkApiStatus(response.data.apiStatus, response.data.data)
            this.hideLoading()
            resolve(response.data)
          })
          .catch(error => {
            this.hideLoading()
            reject(error)
          })
      })
    },

    /** API: 08. 修改行程開始時間 */
    async apiPutUpdateStartDate(params: {
      Id: string
      StartDate: string
      EndDate: string
      updateTime: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateStartDate(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 09. 交通路線 - 查詢簡易交通時間 */
    async ActionFormulaTrafficTime(params: {
      departureLat: string
      departureLon: string
      arrivalLat: string
      arrivalLon: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetFormulaTrafficTime(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 09. 交通路線 - 交通路徑-列表 */
    async ActionRouteList(params: {
      tsdRouteDetailId: string
      trafficType: string
      travelScheduleId: string
      TravelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetRouteList(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 09. 交通路線 - 交通路徑-細節資訊 */
    async ActionRouteDetail(params: {
      poiRouteDetailId: string
      travelScheduleId: string
      TravelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetRouteDetail(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 09. 交通路線 - 交通路徑-TSD的設定路徑 */
    async ActionSetRoute(params: {
      TsdRouteDetailId: string
      PoiRouteDetailId: string
      TravelScheduleId: string
      travelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutSetRoute(params)
          .then(response => {
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 09. 交通路線 - 交通路徑-TSD的設定路徑 */
    async ActionSetCustomRoute(params: {
      TsdRouteDetailId: string
      Duration: string
      Note: string
      TravelScheduleId: string
      travelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutSetCustomRoute(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 09. 交通路線 - 飛機交通設定 */
    async ActionSetSetFlightRoute(params: {
      TsdRouteDetailId: string
      Duration: string
      Note: string
      TravelScheduleId: string
      travelScheduleUpdateTime: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutSetFlightRoute(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 產生 首頁(景點牆) DeepLink (純打開App/無安裝則導至app下載) */
    async apiPostCreateMainDeepLink(params: {
      isOpenAppStore: number
    }): Promise<void> {
      const extendedParams = {
        ...params,
        isOpenAppStore: params?.isOpenAppStore ?? 1,
      }
      return new Promise((resolve, reject) => {
        api
          .PostCreateMainDeepLink(extendedParams)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: 取得會員按讚素材列表 */
    async apiGetLikeMediaListPaging(params: {
      targetMemberId: string
      page: number
      pageSize: number
      lastMediaId?: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetLikeMediaListPaging(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: 取得行程預覽 */
    // language 不填為中文
    async apiGetPreview(params: { TravelScheduleId: string }): Promise<void> {
      this.showLoading()

      return new Promise((resolve, reject) => {
        api
          .GetPreview(params)
          .then(response => {
            this.hideLoading()
            resolve(response.data)
            this.checkApiStatus(response.data.apiStatus, response.data.data)
            if (response.data.apiStatus == '001') {
              this.preViewData = response.data.data
              this.travelScheduleDetail = response.data.data
              this.setPreViewLoading(false) // 關閉預覽載入
              console.log(this.travelScheduleDetail)
            } else {
              this.setPreViewLoading(false) // 關閉預覽載入
            }
          })
          .catch(error => {
            this.hideLoading()
            reject(error)
          })
      })
    },

    /** playlist 匯入 travelSchedule */
    async apiPostPlaylistTravelImport(params: {
      TravelScheduleId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostPlaylistTravelImport(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /** API: SEO - 首頁 */
    async apiGetSeoHome(): Promise<void> {
      // this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetSeoHome({})
          .then(response => {
            resolve(response.data)
            this.homePageTitle = response.data.data.title
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: SEO - Poi詳情 */
    async apiGetSeoPoiDetail(params: { poiId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetSeoPoiDetail(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /** API: SEO - 會員頁 */
    async apiGetSeoMemberCenter(params: { memberId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetSeoMemberCenter(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 取得最後編輯的行程
    ActionLastEditedSchedule(data: any) {
      this.lastEditedSchedule = data
    },

    // 控制開關行程筆記
    ActionOpenTavelScheduleNote(status: boolean) {
      this.isOpenTavelScheduleNote = status
    },

    // 旅遊目的地設定
    setTravelScheduleAreas(data: any) {
      this.travelScheduleAreas = data
    },

    // 設定開關旅遊轉小書光箱
    setShowTSBookPopup(status: boolean) {
      this.showTSBookPopup = status
    },

    //設定預覽載入狀態
    setPreViewLoading(status: boolean) {
      this.preViewLoading = status

      if (status) {
        this.showLoading()
      } else {
        this.hideLoading()
      }
    },

    // 設定載入顯示狀態
    setAllowShowLoading(status: boolean) {
      this.allowShowLoading = status
    },

    // 檢查API回傳狀態
    checkApiStatus(status: string, data: any) {
      const apiData = data

      switch (status) {
        case '001':
          return
        case '004':
          console.log('時間衝突！', apiData)
          this.setUpdateTimeConflict(apiData)
          return
        default:
          return
      }
    },

    //更新時間衝突
    setUpdateTimeConflict(response: any) {
      this.updateTimeConflict = response
    },

    //控制是否為邀請共編
    setIsCollaborationPreView(status: boolean) {
      this.isCollaborationPreView = status
    },

    // 切換Dev版本
    setDevVersion(version: string) {
      this.devVersion = version
    },

    // 行程筆記設定
    setTavelScheduleNote(note: string | any) {
      this.tavelScheduleNote = note
    },

    // 預覽行程時加入共編行程(true即執行加入)
    handlePreViewCheckCollaboration(status: boolean) {
      this.isPreViewCheckCollaboration = status
    },

    // 控制地圖移動到座標
    handleZoomInPolyline(polyline: string) {
      this.zoomInPolyline = polyline
    },

    // 開/關 eMail 匯入訂單光箱
    handleShowEmailOrder(status: boolean) {
      this.isShowEmailOrder = status
    },

    // 訂單航班細節
    handleFlightInfo(status: boolean) {
      this.showFlightInfo = status
    },

    // 航班加入行程
    handleFlightToSchedule(status: boolean) {
      this.showFlightToSchedule = status
    },

    /** 是否可另開視窗 */
    canOpenPopup(link: string) {
      const testPopup = window.open('', '_blank')
      if (!testPopup || testPopup == null) {
        alert('你已設定封鎖彈出式視窗，請開啟後再試一次')
        // window.location.href = link
      } else {
        testPopup.location.href = link
      }
    },
    // 下載App bar 階層是否為最頂
    handleAppDownloadTop(status: boolean) {
      this.appDownloadTop = status
    },

    // 取得會員設定資料
    apiGetMemberSettings(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetMemberSettings()
          .then(response => {
            resolve(response.data)

            const data = response.data.data

            this.travelScheduleSortColumn = data.travelScheduleSortColumn
            this.travelScheduleSortOrder = data.travelScheduleSortOrder
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新會員預設行程排序
    apiPutUpdateTravelScheduleSort(params: {
      orderByColumn: string
      sort: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateTravelScheduleSort(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 設定行程排序
    actionSetTravelScheduleSort(column: string, order: string) {
      this.travelScheduleSortColumn = column
      this.travelScheduleSortOrder = order
      const params = {
        orderByColumn: column,
        sort: order,
      }
      this.apiPutUpdateTravelScheduleSort(params)
    },

    // 取得修改和泰會員資料URL
    apiGetModifyRedirectUri(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetModifyRedirectUri()
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新會員 - 暱稱
    apiUpdateMemberNickname(params: { name: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateMemberNickname(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新會員 - 個人簡介
    apiUpdateMemberIntroduction(params: {
      introduction: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateMemberIntroduction(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新會員 - 個人資料(Email 性別 生日)
    apiUpdateMemberPersonalInfo(params: {
      email: string
      gender: string
      birthday: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateMemberPersonalInfo(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 註冊會員 - 個人資料(Email 性別 生日)
    apiUpdateRegistPersonalInfo(params: {
      email: string
      name: string
      gender: string
      birthday: string
      phoneNumber: string
      phoneCountryCode: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateMemberPersonalInfo(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新會員 - 社群連結
    apiUpdateMemberSocialMedia(params: {
      mainLink: string
      facebook: string
      instagram: string
      twitter: string
      xiaohongshu: string
      tiktok: string
      youtube: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PutUpdateMemberSocialMedia(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 確認和泰會員中心通知解除綁定已收到
    apiPostConfirmCenterUnbindMember(): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostConfirmCenterUnbindMember()
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 取得版位有效廣告(單則)
    apiGetActiveAdvertiseByPosition(params: {
      position: string
      locations?: Array<any>
      categories?: Array<any>
      pois?: Array<any>
      travelSchedules?: Array<any>
      longitude?: number
      latitude?: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetActiveAdvertiseByPosition(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //  使用正則表達式替換所有的 \r\n 為 <br>
    convertNewlinesToBr(str: string) {
      return str.replace(/\r\n/g, `<br>`).replace(/\n/g, `<br>`)
    },

    // 取得 playlist 清單列表
    apiGetPlaylists(params?: {
      poiId?: string
      skip?: number
      take?: number
      isFixedLastQueryPlaylist?: boolean
      isFixedDefault?: boolean
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetPlaylists(params)
          .then(response => {
            const _data = response.data
            if (_data.apiStatus == '001') {
              this.playlistsData = _data.data.page
            }
            resolve(_data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // 取得 playlist 詳細資料
    apiGetPlaylist(params: {
      playlistId: string
      skip?: number
      take?: number
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetPlaylist(params)
          .then(response => {
            const res = response.data
            if (res.apiStatus == '001') {
              this.playlistActiveData = res.data
              this.playlistActivePois = res.data.pois
              this.poiList = res.data.pois // 繪製地圖使用
            }

            const linkParams = {
              playlistId: this.playlistActiveID,
            }
            this.createSharePlaylistDeepLink(linkParams)

            if (this.showMap && this.poiList.length == 0) {
              const msg = '清單中還沒有景點哦'
              ElMessage({
                customClass: 'custom-toast',
                message: msg,
                type: 'warning',
                duration: 2000, // 1.5秒後消失
                offset: window.innerHeight / 2, // 距離網頁頂部（px）
                dangerouslyUseHTMLString: true,
              })
            }

            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // 捲動加載「取得 playlist 詳細資料」
    apiGetMorePlaylist(params: {
      playlistId: string
      skip?: number
      take?: number
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .GetPlaylist(params)
          .then(response => {
            const res = response.data
            if (res.apiStatus == '001') {
              this.playlistActiveData = res.data
              this.playlistActivePois = this.playlistActivePois.concat(
                res.data.pois
              )
              this.poiList = this.poiList.concat(res.data.pois)
            }
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 新增 playlist
    apiPostAddPlaylist(params: {
      name: string
      color?: string | null
      description?: string | null
      permission?: string | null
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostAddPlaylist(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // 修改 playlist
    apiPutUpdatePlaylist(params: {
      id: string
      name: string
      color?: string
      description?: string | null
      permission?: string | null
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PutUpdatePlaylist(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // 刪除 playlist
    apiDelRemovePlaylist(params: { playlistId: string }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .DelRemovePlaylist(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // 刪除 playlist poi
    apiDelRemovePlaylistPois(params: {
      playlistId: string
      poiIds?: object
      isSelectedAll: boolean
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .DelRemovePlaylistPois(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // 新增 Pois 到 playlists，如傳入 playlist 則同時新增 playlist
    apiPostSetPoisToPlaylists(params: {
      playlistIds: object
      poiIds: object
      maintainPlaylistIds: object
      playlist?: object | null
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostSetPoisToPlaylists(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // 將 Pois 複製(加) 到 playlists，如傳入 playlist 則同時新增 playlist
    apiPostCopyPoisToPlaylists(params: {
      playlistIds: object
      poiIds?: object
      sourcePlaylistId?: string
      playlist?: object | null
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostCopyPoisToPlaylists(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 開啟/收合 選擇收藏清單彈窗 */
    togglePlaylistSelectDialog(show: boolean, isFromTsd: boolean = false) {
      this.showPlaylistSelectDialog = show
      this.showPlaylistFromTsd = isFromTsd
    },

    /** 開啟/收合 新增收藏清單彈窗 */
    togglePlaylistCreateDialog(show: boolean, edit: boolean = false) {
      this.showPlaylistCreateDialog = show
      this.isEditPlaylistDialog = edit
    },

    /** 開啟/收合 複製景點彈窗 */
    toggleDuplicatePoiDialog(show: boolean, poi?: string) {
      // 未登入，開啟登入彈窗
      if (!this.isLogin && show) {
        this.toggleLoginDialog(true)
        return
      }
      this.showDuplicatePoiDialog = show
      if (poi) this.duplicatePoiId = poi
    },

    /** 開啟/收合 複製多個景點彈窗 */
    toggleDuplicatePoisDialog(show: boolean) {
      this.showDuplicatePoisDialog = show
    },

    /** 設定暫存被移除於收藏清單中的 pois */
    handleTempUnfavoritePois(type: 'add' | 'remove' | 'reset', id?: string) {
      switch (type) {
        case 'add':
          if (id) this.tempUnfavoritePois.push(id)
          break
        case 'remove':
          this.tempUnfavoritePois = this.tempUnfavoritePois.filter(
            (item: string) => item !== id
          )
          break
        case 'reset':
          this.tempUnfavoritePois = []
          break
      }
    },

    /** 回傳愛心狀態 */
    handleHeartStatus(poi: any): Boolean {
      // 收藏類別 || Poi 彈窗更新時須額外判斷
      if (this.isSelectedPlaylist || this.isPoiHeartUpdate) {
        // 如果 tempUnfavoritePois 內含此 poi 則愛心不亮
        if (this.tempUnfavoritePois.includes(poi.id)) {
          return false
        }
        return true
      }

      // 其他類別直接使用 api 資料判斷
      if (poi.visit && poi.visit.isFavorite) {
        return true
      }
      if (poi.isPoiFavourite) {
        return true
      }
      return false
    },

    // 設定正在瀏覽的收藏ID
    async handlePlaylistActiveID(id: string) {
      this.playlistActiveID = id
      const params = {
        playlistId: id,
      }
      this.apiGetPlaylist(params)
      this.toggleSelectionPlayListStatus(false)

      // const linkParams = {
      //   playlistId: this.playlistActiveID,
      // }
      // this.createSharePlaylistDeepLink(linkParams)
    },

    // 設定收藏清列表
    handlePlaylistsData(data: any) {
      this.playlistsData = data
    },

    // 切換選取狀態
    toggleSelectionPlayListStatus(status: boolean) {
      this.isSelectablePlayList = status
      this.selectedPlayListItems = []
      this.selectAllPlayListID = null

      // 收合ＴＳＤ區塊
      if (status) {
        this.toggleTravel(false)
        this.toggleScheduleDetail(false)
      }
    },

    // 選擇收藏 poi 加入選擇列表
    togglePlayListItemSelection(poiId: string) {
      const index = this.selectedPlayListItems.indexOf(poiId)

      if (index < 0) {
        this.selectedPlayListItems.push(poiId)
      } else {
        this.selectedPlayListItems.splice(index, 1)
      }

      console.log('已選取::', this.selectedPlayListItems)
    },

    // 選擇收藏 poi 選擇全部
    playListItemSelectAll(playlistID: string) {
      this.selectAllPlayListID = playlistID
      if (playlistID) {
        this.selectedPlayListItems = []
        this.playlistActivePois.map(item => {
          this.selectedPlayListItems.push(item.id)
        })
      }
      // 取消全選
      else {
        if (
          this.selectedPlayListItems.length == this.playlistActivePois.length
        ) {
          this.selectedPlayListItems = []
        }
      }
    },

    // 判斷該poi是否被選取
    isPlayListItemSelected(id: string) {
      return this.selectedPlayListItems.includes(id)
    },

    /** 產生 分享 DeepLink */
    async createSharePlaylistDeepLink(params: {
      playlistId: string
      isOpenAppStore: number
    }): Promise<void> {
      this.showLoading()
      const extendedParams = {
        ...params,
        isOpenAppStore: params?.isOpenAppStore ?? 1,
      }
      return new Promise((resolve, reject) => {
        api
          .PostCreateSharePlaylistDeepLink(extendedParams)
          .then(response => {
            resolve(response.data)
            this.playlistDeepLink = response.data.data

            this.hideLoading()
          })
          .catch(error => {
            reject(error)
            this.hideLoading()
          })
      })
    },

    /** 切換 Poi 收藏清單是否更新 */
    handleSetPoiHeartUpdate(status: boolean) {
      this.isPoiHeartUpdate = status
    },

    /** 切換複製景點彈窗是否開啟於 Poi 彈窗 */
    handleSetDuplicateDialogFromPoi(status: boolean) {
      this.isDuplicateDialogFromPoi = status
    },

    async apiPostAddCustomPoiForWeb(params: {
      id?: string
      name: string
      categoryId: string
      longitude: string
      latitude: string
      address: string
      description: string
      media: any[]
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostAddCustomPoiForWeb(params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },
    // POI 牌卡加入愛心邏輯判斷
    handlePoiCardLikeStatus(id: string, isLike: boolean) {
      if (isLike) {
        // 加入愛心
        if (!this.poiIsLikeList.includes(id)) {
          this.poiIsLikeList.push(id)
        }
      } else {
        //移除愛心
        this.poiIsLikeList = this.poiIsLikeList.filter(item => item !== id)
      }
    },

    // 初始poi愛心列表
    InitPoiCardLikeStatus(isLikeList: any) {
      this.poiIsLikeList = []
      if (isLikeList && isLikeList.length > 0) {
        this.poiIsLikeList = isLikeList
      }
    },

    handlePoiIsLikeShow(id: string) {
      // 判斷POI列表愛心顯示
      if (this.poiIsLikeList.length > 0) {
        return this.poiIsLikeList.some(item => item === id)
      }
    },

    // === 達人推薦 ===
    /** 顯示 / 隱藏達人搜尋列下拉選單 */
    toggleExpertSearchActive(status: boolean): void {
      this.isExpertSearchActive = status
    },

    /** 顯示 / 隱藏達人找地區彈窗 */
    toggleExpertSearchAreaDialog(status: boolean): void {
      this.showExpertSearchAreaDialog = status
    },

    /** 顯示 / 隱藏達人篩選行程彈窗 */
    toggleTourFilterDialog(status: boolean): void {
      this.showTourFilterDialog = status
    },

    /** 儲存所有可查詢地區資料 */
    setAllSearchLocationData(data: any) {
      this.allSearchLocationData = data
    },

    /** 儲存搜尋選項 */
    setSearchingKey(data?: any) {
      if (data) {
        this.searchingKey = data
      } else {
        // 恢復預設值
        this.searchingKey = {
          locationKey: [],
          searchKey: '',
          totalDayKey: [],
          monthKey: [],
          travelPreferKey: [],
        }
      }
    },

    /** locationKey 轉換為 name */
    locationKeyToName(key: string): string {
      if (!this.allSearchLocationData.length) return ''
      return this.allSearchLocationData.find(
        (item: any) => item.locationKey === key
      ).name
    },

    /** 獨家行程 */
    apiGetExclusive(): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetExclusive()
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 人氣排行 */
    apiGetPopularRanking(params: {
      page: number
      pageSize?: number
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetPopularRanking(params)
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 九宮格地區 */
    apiGetNineGridLocation(params: { gridCount?: number }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetNineGridLocation(params)
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 查詢地區 */
    apiGetSearchLocation(params: { keyword?: string }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetSearchLocation(params)
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 儲存選擇的地區 */
    apiPostSaveLocation(params: { locationKey: string[] }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostSaveLocation(params)
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 精選行程 */
    apiPostHighlight(params: { locationKey: string[] }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostHighlight(params)
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 行程攻略 */
    apiPostTourGuide(params: {
      page: number
      pageSize?: number
      locationKey?: string[]
      searchKey?: string
      totalDayKey?: string[]
      monthKey?: string[]
      travelPreferKey?: string[]
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostTourGuide(params)
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 行程攻略選項 */
    apiPostTourGuideOption(params: { locationKey?: string[] }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostTourGuideOption(params)
          .then(response => {
            const { data } = response
            if (data.apiStatus === '002') {
              ElMessage({
                customClass: 'custom-toast',
                message: data.message,
                type: 'error',
                duration: 2000,
                offset: 52,
              })
            }
            resolve(data)
          })
          .catch(error => {
            ElMessage({
              customClass: 'custom-toast',
              message: error,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(error)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    // === 達人推薦 - [詳細] ===
    /** 展開/ 收合 行程細節 */
    toggleExpertSchedulelMin(isMin: boolean) {
      this.isExpertSchedulelMin = isMin
    },

    /** 行程總覽 */
    apiGetExpertTour(params: { travelScheduleId: string }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .GetExpertTour(params)
          .then(response => {
            if (response.data.apiStatus == '001') {
              this.travelScheduleInfo = response.data.data.overview
              this.expertDayList = response.data.data.dayList
            }
            resolve(response.data)
          })
          .catch(err => {
            ElMessage({
              customClass: 'custom-toast',
              message: err,
              type: 'error',
              duration: 2000,
              offset: 52,
            })
            reject(err)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 達人行程 按讚,取消按讚 */
    apiPostTravelScheduleLike(params: {
      travelScheduleId: string
      isLike: boolean
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostTravelScheduleLike(params)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    /** 複製達人行程 */
    apiPostTravelScheduleCopy(params: {
      travelScheduleId: string
    }): Promise<void> {
      this.showLoading()
      return new Promise((resolve, reject) => {
        api
          .PostTravelScheduleCopy(params)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },

    /** 產生 達人推薦行程 */
    apiPostCreateExpertTourDeepLink(params: {
      travelScheduleId: string
    }): Promise<void> {
      return new Promise((resolve, reject) => {
        api
          .PostCreateExpertTourDeepLink(params)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // 設定當前顯示的天
    setExpertCurrentDay(day: number) {
      this.expertCurrentDay = day
    },
  },
})
