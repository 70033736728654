// Composables

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/home.vue'
      ),
  },
  {
    path: '/index',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/index.vue'
      ),
  },
  {
    path: '/lineCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/lineCallback.vue'
      ),
  },
  {
    path: '/appleCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/appleCallback.vue'
      ),
  },
  {
    path: '/signupCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/registCallback.vue'
      ),
  },
  {
    path: '/hotaiCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/hotaiCallback.vue'
      ),
  },
  {
    path: '/about',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/about.vue'
      ),
  },
  {
    path: '/maptest',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/maptest.vue'
      ),
  },
  {
    path: '/component',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/component.vue'
      ),
  },
  {
    path: '/member',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/member.vue'
      ),
  },
  {
    path: '/discount/agoda',
    component: () => import(/* webpackChunkName: "discountAgoda" */ '@components/discount/contents/agoda.vue'),
    props: (route: any) => {
      return {
        isReloadMember: true,
      }
    },
  },
  {
    path: '/expert',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/expert/ExpertLayout.vue'
      ),
    children: [
      {
        path: '',
        name: '行程靈感',
        alias: '/expert/inspiration',
        component: () =>
          import(
            /* webpackChunkName: "apple" */ '@components/home/expert/inspiration.vue'
          ),
      },
      {
        path: 'area',
        name: '探索地區',
        component: () =>
          import(
            /* webpackChunkName: "apple" */ '@components/home/expert/area.vue'
          ),
      },
      {
        path: 'search',
        name: '搜尋與篩選結果',
        component: () =>
          import(
            /* webpackChunkName: "apple" */ '@components/home/expert/search.vue'
          ),
      },
    ],
  },

  {
    path: '/expertDetail',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/expert/expertDetail.vue'
      ),
  },
]

// const router = createRouter({
//  history: createWebHistory(process.env.BASE_URL),
//  routes,
// })

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
